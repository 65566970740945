import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./faq.css";
import axios from "axios";
import { toast } from "react-toastify";

function FAQ() {
  const [data, setData] = useState(null);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/faqs`)
      .then((res) => {
        setData(res?.data?.faqs);
      })
      .catch((err) => {
        if (err?.message == "Network Error") {
          toast.error("Check your internet connection");
        }
      });
  }, []);
  return (
    <div className="mb-3">
      <div className="info-page-header">
        <div className="py-2 py-lg-5">
          <h1 className="text-center">FAQ's</h1>
        </div>
      </div>
      <div className="p-3 p-lg-5">
        <div className="bg-white shadow p-2 p-lg-5">
          <Accordion defaultActiveKey="0">
            {data?.map((e, index) => {
              return (
                <Accordion.Item eventKey={String(index)}>
                  <Accordion.Header>{e?.questions}</Accordion.Header>
                  <Accordion.Body
                    dangerouslySetInnerHTML={{
                      __html: e?.answers,
                    }}
                  ></Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
